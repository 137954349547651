//!* Elements
	.group-form {
		margin-bottom: $spacing * 2;
		background: rgba(255,255,255,0.4);
		background: rgba($redrum-3,0.04);
		border-radius: $radius;
		border-left: 5px solid;
		border-color: $redrum-3 !important;
		padding: $spacing * 2;

		.label {
			color: rgba($redrum-3,.4);
		}

		@media (max-width : 767px) {
			padding: $spacing + 5;
			margin-bottom: $spacing + 5;
		}

		h1, h2, h3, h4 {
			font-weight: 500;
			margin-bottom: $spacing * 2;
			color: $redrum-3;

			@media (max-width : 767px) { margin-bottom: $spacing + 5; }

			.btn { vertical-align: top; }
		}

		.label { background: none !important; }

		.row {
			> .form-group { margin: 0; }
		}
	}

	.group-collapse, .group-links {
		border-radius: $radius;
		box-shadow: $shadow-3;
		background: white;
		margin-bottom: $spacing * 2;

		@media (max-width : 767px) { margin-bottom: $spacing; }

		> .panel + .panel, > li + li { border-top: 1px solid $color-border !important; }

		.link-nav {
			.material-icons {
				@include transform(rotate(0deg));
				@include transition(all .15s linear);
			}

			&:not(.collapsed) .material-icons { @include transform(rotate(45deg)); }
		}

		&:last-child { margin-bottom: 0; }
	}

	.sortable {
		user-select: none;

		&.sortable-text {
			position: relative;

			&:before, &:after {
				content: '';
				clear: both;
				display: block;
			}

			> li {
				float: left;
				width: 100%;
				position: relative;
				background: white !important;
				cursor: move;
    			cursor: grab;

				&:first-child { border-radius: $radius $radius 0 0; }
				&:last-child { border-radius: 0 0 $radius $radius; }

				&.placeholder {
					height: 45px;
					background: $color-border !important;

					&:first-child { border-radius: $radius $radius 0 0; }
					&:last-child { border-radius: 0 0 $radius $radius; }
				}

				a.link-default {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					background: none !important;
					height: 45px;
					color: $color-body !important;
					cursor: grab;

					&:active {
						transition: box-shadow 300ms ease, border-radius 300ms ease;
						cursor: -webkit-grabbing !important;
						cursor: grabbing !important;
					}
				}

				&:first-child:active + li:not(.placeholder) {
					border-color: white !important;
				}

				&:first-child:active + li:not(.placeholder) {
					border-radius: $radius $radius 0 0;
				}

				&.placeholder:nth-last-child(2) {
					border-radius: 0 0 $radius $radius !important;
				}

				&:active {
					z-index: 9999;
					box-shadow: $shadow-3 !important;
					border-radius: $radius * 1.5;
					background: white;
					border-color: white !important;
					cursor: -webkit-grabbing !important;
					cursor: grabbing !important;
				}
			}
		}

		&.sortable-photo {
			background: white;
			border-radius: $radius;
			border: 1px solid $border-color;
			border-bottom-width: 2px;
			padding: 1rem 0 0 1rem;
			margin: 5px 0 0;
			order: 2;

			&:before, &:after {
				content: '';
				clear: both;
				display: block;
			}

			> li {
				position: relative;
				float: left;
				margin: 0 1rem 1rem 0;
				background: $color-border;
				width: 120px;
				height: 120px;
				border-radius: $radius * 1.5;
				cursor: move;
				transition: box-shadow 300ms ease;
				cursor: move;
    			cursor: grab;

				img {
					display: block;
					width: 100%;
					height: 100%;
					border-radius: $radius;
					object-fit: cover;
					cursor: move;
    				cursor: grab;

    				&:active {
						cursor: -webkit-grabbing !important;
						cursor: grabbing !important;
					}
				}

				.dropdown {
					position: absolute;
					right: 5px;
					bottom: 5px;

					.dropdown-menu { min-width: inherit; }
				}

				&.placeholder {
					background: none !important;

					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: none !important;
						border-radius: $radius * 1.5;
						border: 2px dashed $color-border !important;
					}
				}

				&:active {
					cursor: -webkit-grabbing !important;
					cursor: grabbing !important;
					z-index: 9999;
					box-shadow: $shadow-3 !important;
				}
			}
		}
	}

	.tabs-group {
		display: block;
		margin-bottom: $spacing;
		width: 100%;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		overflow: auto;
		li {
			display: inline-block;
			vertical-align: bottom;
			a {
				display: block;
				border-radius: $radius;
				padding: 8px 20px;
				line-height: 1em;
				font-size: $fsize + 1;
				font-weight: 500;
				color: $color-body;
				position: relative;
				transition: all 300ms ease;
				
				&.active, &:hover { 
					color: $redrum-3 !important;
					background: rgba($redrum-3,.2) !important;
				}
			}
		}
		&.tabs-lang {
			li {
				a {
					font-weight: 600;
					width: 50px;
					height: 50px;
					line-height: 1em;
					padding: 18px 0 19px;
					color: $color-opacity;
					text-align: center;
					border-radius: 50px;
					background: rgba(0,0,0,0.03);

					&.active, &:hover {
						background: rgba($redrum-3,.2);
						color: $redrum-3 !important;
					}
				}				
			}
		}
	}

	.tab-content {
		margin-bottom: $spacing * 2;
		@media (max-width : 767px) { margin-bottom: $spacing; }
		&:last-child { margin-bottom: 0; }
		&.lang {
			border: 1px solid $color-border;
			border-bottom-width: 3px;
		}
	}

	.filter-box {
		margin-bottom: $spacing * 2 !important;
		@media (max-width : 767px) { margin-bottom: $spacing + ($spacing / 2) !important; }
		.col-primary.select-box:after { right: 0; margin-top:-12px; }
	}
	
	.btn {
		position: relative;
		z-index: 1;
		box-shadow: $shadow-3 !important;
		padding: 8px 30px;
		border: none;
		font-size: $fsize + 1;
		font-weight: 500;
		border-radius: $radius * 2;
		letter-spacing: 0.015em;
		outline: none !important;
		@include transform(scale(1));
		@include transition(all 300ms ease);

		&.btn-lg { padding: 11px 37px; }

		&.btn-xl {
			padding: 15px 47px;
			font-size: $fsize + 1;

			.material-icons {
				font-size: 22px;
				line-height: 22px;
			}
		}

		&:hover { box-shadow: $shadow-4 !important; }

		&:active { @include transform(scale(0.95)); }

		&[class$="-transparent"] { box-shadow: none !important; background: none !important; }
		
		.material-icons {
			font-size: 20px;
			line-height: 18px;
		}
		
		> * {
			display: inline-block;
			vertical-align: middle;
		}
		
		> * + * { margin-left: ($spacing / 2); }

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background: rgba(255,255,255,0.09);
			border-radius: $radius;
			opacity: 0;
			visibility: hidden;
			@include transition(all .15s linear);
		}
		&[class$="-transparent"]:before { background: rgba(0,0,0,0.09) !important; }
		&:hover:before, &:active:before {
			opacity: 1;
			visibility: visible;
		}
		&:active:before { background: rgba(255,255,255,0.23); }
		&[class$="-transparent"]:active:before { background: rgba(0,0,0,0.23); }
		&.disabled, &:disabled {
			opacity: 0.35 !important;
			box-shadow: none !important; 
			@include transform(scale(1));
			-webkit-filter: grayscale(1);
			-moz-filter: grayscale(1);
			cursor: not-allowed !important;
		}
	}

	.btn-icon {
		padding: 10px;
		border-radius: 50%;
		line-height:1em;

		> * { margin: 0 !important; }

		.material-icons {
			display: block;
			font-size: 24px;
			line-height: 1em !important;
		}

		&:before { border-radius: 50%; }

		&.btn-xs {
			padding: 7px;

			.material-icons { font-size: 18px !important; }
		}

		&.btn-lg { padding: 17px; }
	}

	.btn-default-2 { background:$redrum-3; color: white !important; }
	.btn-default-2-transparent { color: $redrum-3 !important; }

	.btn-default-1 { background: $redrum-2 !important; color: $redrum-3 !important }
	.btn-default-1-transparent { color: $redrum-3 !important; }

	.btn-default-3 { background: $redrum-1 !important; color: $redrum-3 !important; }
	.btn-default-3-transparent { color: $redrum-3 !important; }

	#blocks-container .actions .btn-default-3,
	.btn-delete,
	.sortable .btn-icon,
	.table .btn-icon { background: $redrum-1 !important; color: $redrum-3 !important; }

	#form-submit { background: $redrum-3 !important; color: $redrum-1 !important; }
	
	#blocks-container .actions .btn-delete {
		color: $color-error-alt !important;
		background: $color-error !important;
	}

	.link-default {
		position: relative;
		display: block;
		padding: ($spacing / 2.15) $spacing;
		color: $color-body !important;
		cursor: pointer;
		transition: all 300ms ease;

		span {
			display: inline-block;
			vertical-align: middle;
			font-weight: 500;
			padding-left: 39px;
		}

		.material-icons {
			position: absolute;
			top: 50%;
			font-size: 22px;
			margin-top: -.5em;
			left: $spacing;
			vertical-align: middle;
		}

		.badge {
			position: absolute;
			right: $spacing;
			top: 50%;
			margin-top: -12px;
		}

		& + ul {
			li:first-child {
				padding-top: ($spacing / 5);
			}

			li:last-child {
				padding-bottom: ($spacing / 2);
			}
		}

		&.no-icon span { padding-left: 0; }

		&.link-nav:not(.collapsed), &.active, &:hover {
			color: $redrum-3 !important;
		}
	}

	button.link-default, label.link-default {
		width: 100%;
		border: none;
		background: none;
		text-align: left;
		margin: 0;
	}

	span.link-default:hover {
		color: $color-body !important;
		cursor: default !important;
	}

	.sublink-default {
		display: block;
		padding: ($spacing / 2.15) $spacing ($spacing / 2.15) ($spacing + 32px + ($spacing / 3));
		color: $color-body !important;
		cursor: pointer;

		&.sublink-active {
			font-weight: 500;
			background: rgba(0,0,0,0.04);
		}

		.sublink-active,
		.sublink-default:hover {
			color: $redrum-3 !important;
		}
	}

	.alert {
		position: relative;
		font-weight: 500;
		border: none;
		border-radius: $radius;
		padding: $spacing * 2;
		color: white;
		margin-bottom: $spacing * 2;

		&.alert-xs { padding: $spacing; }

		&.alert-default {
			background: none;
			border: 2px solid $color-border !important;
			color: $color-opacity !important;
		}

		&.alert-success { background: $color-success; }
		&.alert-danger { background: $color-error; }
		&.alert-info { background: $color-info; }
		&.dashed { border-style: dashed !important; }

		&.fade {
			@include transition(all 300ms ease);
			&:not(.show) {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
				font-size: 0 !important;
				margin: 0 !important;
			}
			//@include transform(scale(0));
			&.show { @include transform(scale(1)) }
		}

		ul { padding-left: 16px; }

		.btn-close {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: none;
			border: none;
			box-shadow: none;
			opacity: 0;
		}

		&.message-order {
			padding: ($spacing / 2) $spacing;
		}

		@media (max-width : 767px) {
			padding: $spacing;
			margin-bottom: $spacing;
		}
	}

	.brand-image {
		display: block;
		padding: $spacing; 

		img {
			max-width: 100%;
			max-height: 70px;

		}
	}

	.dropdown { position: relative; }

	.dropdown-menu {
		position: absolute;
		text-align: left;
		margin: 0;
		top: -2px !important;
		left: -2px !important;
		z-index: 95;
		border:none;
		box-shadow: $shadow-3 !important;
		border-radius: $radius;
		padding: 0;
		list-style: none;
		background: white;
		min-width: 200px;
		max-width: 300px;
		opacity: 0;
		visibility: hidden;
		@include transform(scale(0.95) !important);
		@include transition(all 300ms ease);

		li + li { border-top: 1px solid $color-border-alt; }

		a { outline: none !important; }

		&.right {
			left: auto !important;
			right: -2px !important;
		}
	}

	.dropup .dropdown-menu {
		top: auto !important;
		bottom: -2px !important;
	}

	.show > .dropdown-menu {
		@include transform(scale(1) !important);
		visibility: visible;
		opacity: 1;
	}

	.modal {
		.alert {
			margin-bottom: $spacing;
		}

		.modal-header {
			display: block;
			padding: $spacing ($spacing * 2) $spacing;
			// background: rgba(0,0,0,0.07);
			border: none;

			.modal-title {
				font-weight: 500;
				color: $redrum-3;
			}
		}

		.modal-body { padding: 0 ($spacing * 2); }

		.modal-footer {
			padding: $spacing / 2;
			// background: rgba(0,0,0,0.07);
			border: none;
		}

		&.fade {
			transition: all 300ms ease;

			.modal-dialog { @include transform(scale(0.9)); }
			&.show .modal-dialog { @include transform(scale(1)) }
		}

		&#ordenar {
			.modal-header {
				padding-bottom: $spacing * 1.5;
			}

			.modal-body {
				ul {
					font-size: $fsize + 1;
				}

				> div + div {
					margin-top: $spacing * 1.5;
				}

				h4 {
					font-size: 1.25em;
					color: $redrum-3;
					margin-bottom: .5em;

					.material-icons {
						margin-right: .23em;
					}
				}
			}

		}
		@media (min-width : 768px) {
			.modal-dialog.modal-sm { max-width: 400px; }
		} 
		@media (max-width : 767px) {
			.modal-dialog { margin: $spacing; }
			.modal-header, .modal-body { padding: $spacing; }
		}

		.modal-dialog {
			> form,
			> div {
				box-shadow: $shadow-4;
				pointer-events: auto;
			}
		}
	}

	.modal-backdrop {
		background: $redrum-3;

		&.show { opacity: 0.65; }
	}

	@keyframes opacity_in {
		from { opacity: 0; }
		to { opacity: 1; }
	}
	@keyframes opacity_out {
		from { opacity: 1; }
		to { opacity: 0; }
	}
	@keyframes opacity_in_alt {
		from { opacity: 0; }
		to { opacity: 0.65; }
	}
	@keyframes opacity_out_alt {
		from { opacity: 0.65; }
		to { opacity: 0; }
	}

	.tooltip {
		.arrow { opacity: 0 !important; }

		.tooltip-inner {
			padding: 8px 10px;
			font-size: 12px;
			line-height: 15px;
			font-weight: 600 !important;
			border-radius: $radius;
			background: rgba(0,0,0,1);
			color: $white;

			hr {
				margin: 10px 0;
				border-top: 1px solid rgba($white,0.2);
			}
		}
	}

	.tooltip-helper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		display: block;
	}