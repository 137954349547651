//!* Tables
	.table-responsive {
		border: none !important;
		margin: $spacing 0 0 !important;
	}

	.table {
		border: none !important;
		font-size: 1em;
		margin-bottom: $spacing * 3 !important;
		color: $color-body;

		// @media (max-width : 767px) { margin-bottom: $spacing; }

		th, td {
			padding: .5rem;
			border-color: $color-border !important;
		}

		th {
			text-transform: none !important;
			font-weight: 500 !important;
			font-size: $fsize - 1 !important;
			vertical-align: bottom !important;
    		line-height: 1em !important;
    		padding-bottom: 10px !important;
		}

		thead tr:first-child th {
			border-top: none !important;
		}
	}

	.table-border {
		th + th, 
		td + td { border-left: 1px solid; }
	}

	.table-attributes {
		margin: 0 !important;

		th {
			padding-top: 13px !important;

			small { text-transform: none; }
		}

		.var, .att { vertical-align: middle; }

		.var {
			min-width: 200px;
			text-align: right;
			font-size: 90%;
			word-break: break-all;

			span {
				display: block;
				line-height: 1.15em;
			}
		}

		.att {
			padding: 0 !important;
			width: 100%;

			.form-control {
				border: none !important;
				padding-right: 15px !important;
				padding-left: 15px !important;
				box-shadow: none !important;

				&.editable {
					height: auto !important;
					min-height: 80px !important;
				}
			}

			textarea.form-control {
				min-height: 80px !important;
			}
		}
	}

	.table-default {
		margin-bottom: ($spacing * 4) !important;
		.dropdown-menu { min-width: 101%; }

		th:first-child, td:first-child {
			padding-left: $spacing * 2;
			@media (max-width : 767px) {
				padding-left: $spacing;
			}
		}

		th:last-child, td:last-child {
			padding-right: $spacing * 2;
			@media (max-width : 767px) {
				padding-right: $spacing;
			}
		}

		th, td { vertical-align: middle !important; }

		th {
			border-bottom-color: $color-border !important;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 85%;
			color: $color-opacity;
			vertical-align: bottom !important;
    		line-height: 1em !important;
    		padding-bottom: 10px !important;
    		//background: #e8e8e8;

    		.checkbox, .radio { margin-top: -2px; }
		}

		td {
			border-top: none;
			border-bottom: 1px solid $color-border !important;
		}

		.td-news {
			padding-top: .35rem;
			padding-bottom: .35rem;
			line-height: 1.3;

			strong {
				color: $redrum-3;
			}

			small {
				opacity: .75;
			}
		}

		.actions {
			width: 50px;
			text-align: center;
			white-space: nowrap;

			&.text-left { text-align: left !important; }
			&.text-right { text-align: right !important; }

			.btn-icon {
				padding: 6px;

				.material-icons { font-size: 20px !important; }
			}
		}

		tbody {
			tr {
				&:hover td { background: #d8d8d8 !important; }

				&:nth-child(odd) td {
					background: #e8e8e8;
				}
			}
		}


		tfoot td {
			border-top: 2px solid $color-border !important;
			border-bottom: none !important;
		}

		.checkbox, .radio {
			padding: 0 !important;
			width: 18px; 
			height: 18px;
			text-transform: none;
		}

		.slider {
			padding: 0 !important;
			width: 38px;
			height: 20px;
		}
	}