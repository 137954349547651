//!* Forms
	.label {
		display: block;
		margin: 0;
		padding: 0 0 .25em $radius;
		font-weight: normal;
		font-size: 1em;
		font-weight: 500;
		color: $color-opacity;
		text-align: left;
		user-select:none;
		transition: color 300ms ease;
	}

	.form-group {
		display: flex;
		flex-direction: column;
		position: relative;
		margin-bottom: ($spacing + 5px);

		.label {
			order: 1;

			a {
				color: currentColor;
				text-decoration: underline !important;

				&:hover {
					color: $gray-900;
				}
			}
		}

		.form-control {
			order: 2;
		}
		
		.option-group {
			line-height: 1.5em !important;
			height: auto !important;
			min-height: calc(1.85em + 1rem + 3px);
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			border-bottom: 2px solid $color-border;
			transition: border 300ms ease;

			&.has-error { border-color: $color-error !important; }
		}

		.badge {
			margin: 10px 0 13px;
		}

		.form-counter-label {
			margin: 0 !important;
			position: absolute;
			top: -2px;
			right: 0;
		}

		&[class*="col-"] > .form-counter-label { right: 15px; }

		.form-counter { margin-top: 6px !important; }

		&:not(.form-file) .form-control:focus + .label { color: $redrum-3; }
	}

	.has-error {
		.form-control {
			border-color: $color-error !important;
			
			&:focus {
				border-color: $color-error !important;
				box-shadow: 0 0.1rem 0.3rem 0.05rem rgba($color-error, 0.5) !important;
			}	
		}

		> .label { color: $color-error !important; }
	}

	.form-control {
		border-radius: $radius * 1.5;
		border: 1px solid $color-border;
		border-bottom: 2px solid darken($color-border, 2%);
		box-shadow: none !important;
		background: white !important;
		color: $color-body;
		font-size: $fsize;
		transition: border 300ms ease, color 300ms ease, opacity 300ms ease, box-shadow 300ms ease;
		
		&:focus {
			box-shadow: 0 0.1rem 0.3rem 0.05rem rgba($redrum-3, 0.5) !important;
			border-color: rgba($redrum-3, .33) !important;
			border-bottom-color: rgba($redrum-3, .5) !important;
		}
		
		&:disabled { opacity: 0.5; }

		&.form-control-lg {
			height: calc(1.85em + 1rem + 3px);
		    padding: 0.75rem .85rem;
		    line-height: 1.85;
		}
	}

	.form-control:disabled + .label { opacity: 0.5; }

	textarea.form-control {
		min-height: 100px;
		resize: vertical;

		&[rows='1'] {
			max-height: 500px;
			resize: vertical;
			padding: .75rem .85rem !important;
			min-height: calc(1.85em + 1rem + 3px) !important;
			max-height: inherit !important;
			height: auto;
		}	
	}

	.select-box {
		&:after {
			content: 'keyboard_arrow_down';
			font-family: 'Material Icons';
			font-size: 24px;
			line-height: 1em;
			width: 1em;
			height: 1em;
			color: $color-opacity;
			position: absolute;
			bottom: 11px;
			right: 7px;
			margin-top: 0;
			pointer-events: none;
			user-select: none;
			transition: color 300ms ease;
		}

		&[class*="col-"]:after { right: 25px; }

		.form-control {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			line-height: 1.5em;
			padding-right: 44px !important;

			&:invalid { color: $color-opacity !important; }
		}	

		&.has-error:after { color: $color-error !important; }	
	}

	.checkbox, .radio {
		position: relative;
		margin: 0 !important;
		padding: 11px 20px 11px 28px;
		cursor: pointer;
		font-weight: 500;
		z-index: 1;
		user-select: none;

		span {
			display: block;
			color: $color-opacity;
			@include transition(all 300ms ease);

			&:before, &:after {
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				width: 18px;
				height: 18px;
				line-height: 14px;
				margin-top: -9px;
			}

			&:before {
				margin-top: -9px;
				left: 0;
				content: '';
				border-radius: $radius;
				border: 2px solid $color-opacity;
				z-index: 1;
				@include transition(border-color 300ms ease);
			}

			&:after {
				padding: 2px;
				font-size: 14px;
				font-family: 'Material Icons';
				z-index: 2;
				color: $background-body;
				@include transform(scale(0.5));
				@include transition(all 300ms ease);
				opacity: 0;
			}
		}

		input:checked + span { color: $redrum-3; }

		input:checked + span:before {
			border-width: 9px;
			border-color: $redrum-3;
		}

		input:checked + span:after {
			@include transform(scale(1));
			opacity: 1;
		}
	}

	.radio {
		input + span:before { @include transition(all .1s linear); }
		input:checked + span:before { border-width: 5px !important; }
	}

	.slider {
		display: block;
		position: relative;
		z-index: 1;
		font-weight: 500;
		padding: 11px 35px 11px 45px; 
		margin: 0;
		cursor: pointer;

		&.slider-inline {
			display: inline-block;
		}

		span {
			display: block;
			color: $color-opacity;
			@include transition(all 300ms ease);

			&:before, &:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				opacity: 1;
				@include transform(translateX(0px));
				@include transition(all 300ms ease);
			}
			&:before {
				z-index: 1;
				width: 37px;
				height: 12px;
				border-radius: 9px;
				margin-top: -6px;
				background: $color-body;
				opacity: .12;
			}
			&:after {
				position: absolute;
				width: 18px;
				height: 18px;
				margin-top: -9px;
				border-radius: 9px;
				background: $color-opacity;
				//box-shadow: 0 1px 3px 1px rgba(0,0,0,0.4);
				z-index: 2;
			}
		}
	}

	.slider input:checked + span:before {
		opacity: 0.33;
		background: $redrum-3;
	}

	.slider input:checked + span:after {
		background: $redrum-3;
		@include transform(translateX(20px));
	}
	.slider input:checked + span { color: $redrum-3; }
	.radio span:before { border-radius: 50%; }
	.radio span:after { display: none !important; }
	.checkbox span:after { content:'check'; }
	.radio span:after { content:'fiber_manual_record'; font-size: 14px; }

	.form-file {
		display: block;
		font-weight: 400;
		position: relative;
		min-height: 58px;
		padding-left: (72px + ($spacing * 0.75));
		width: 100%;

		.placeholder {
			color: $color-opacity;
			font-style: italic;
		}

		.form-control {
			padding-top: .5rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			margin: 0;
			cursor: pointer;
		}

		.btn {
			position: absolute;
			top: 0;
			left: 0;
			width: 72px;
			height: 72px;
			border: 1px solid $color-border !important;
			border-bottom-width: 2px !important;
			background: white !important;
			color: $color-body !important;
			box-shadow: none !important;
			padding: 15px !important;
			border-radius: $radius;

			&:hover {
				box-shadow: none !important;
				border-color: $color-opacity !important;
			}

			img {
				position: absolute;
				z-index: 2;
				top: 4px;
				left: 4px;
				width: 62px;
				height: 62px;
				display: block;
				border-radius: $radius;
				display: block;
				z-index: 2;
				object-fit: cover;
			}

			.material-icons {
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}

		input:focus {
			& ~ .form-control {
				box-shadow: 0 0.1rem 0.3rem 0.05rem rgba($redrum-3, 0.5) !important;
				border-color: rgba($redrum-3, .33) !important;
				border-bottom-color: rgba($redrum-3, .5) !important;
			}

			& ~ .label {
				color: $redrum-3 !important;
			}
		}

		&.has-error {
			.label { color: $color-error !important; }
			.right { border-bottom-color: $color-error !important; }
			.btn { border-color: $color-error !important; }

			input:focus {
				& ~ .form-control {
					border-color: $color-error !important;
					box-shadow: 0 0.1rem 0.3rem 0.05rem rgba($color-error, 0.5) !important;
				}

				& ~ .label {
					color: $color-error !important;
				}
			}
		}
	}

	.checkbox, .radio, .slider, .form-file {
		user-select: none;

		input {
			position: absolute;
			left: -9999em;
			top: 0;
			opacity: 0;
		}

		input:disabled + span {
			cursor: not-allowed !important;
			opacity: 0.35;
		}

		&:has(input:disabled) { cursor: not-allowed; }
	}

	::placeholder { color: $color-opacity !important; font-style: italic !important; }
	
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus
	input:-webkit-autofill, 
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: $color-body;
		-webkit-box-shadow: 0 0 0px 1000px white inset;
		transition: background-color 5000s ease-in-out 0s, border .15s linear, color .15s linear;
	}

	#login :-webkit-autofill {
		-webkit-text-fill-color: $color-body;
		box-shadow: 0 0 0px 1000px white inset !important;
	}

	.datepicker {
		transform: none !important;
		opacity: 1 !important;
		visibility: visible !important;
		top: 100% !important;
		user-select: none;
		z-index: 9999 !important;
		width: 300px;
		background: white;
		border-radius: $radius;
		border: none;
		box-shadow: $shadow-1;
		margin: ($spacing / 2) 0;
		padding: 0;
		background: white;
		text-align: center;
		animation: open_datepicker .3s linear;
		transform-origin: top left;
		@media (max-width : 767px) {
			width: inherit !important;
			left: $spacing - 10px !important;
			right: $spacing - 10px !important;
		}

		table {
			border-spacing: 5px;
    		border-collapse: separate;
			width: 100% !important;
			td, th {
				padding: $spacing / 3 !important;
				text-align: center !important;
				line-height: 1em !important;
				border: none !important;
				color: $color-body;
				border-radius: $radius;
				letter-spacing: 0;
				transition: all .3s linear;
				@media (max-width : 359px) { padding: $spacing ($spacing / 2) !important; }
			}
			th {
				font-weight: bold;
				&.datepicker-switch, &.today { cursor: pointer; }
				&.prev, &.next {
					cursor: pointer;
					font-size: 0;
					padding: $spacing / 4 !important;
					&:before {
						margin: 0 auto;
						display: block;
						font-family: 'Material Icons';
						font-size: 24px;
						line-height: 1em;
						width: 1em;
						height: 1em;
						text-align: center;
					}
				}
				&.prev:before { content: 'navigate_before'; }
				&.next:before { content: 'navigate_next'; }
				&.datepicker-switch, &.today, &.prev, &.next {
					transition: all .3s linear;
					border-radius: $radius;
					&:hover { background: rgba(0,0,0,0.04); }
				}
			}
			td {
				padding: 0 !important;
				> span {
					display: inline-block;
					padding: $spacing / 2;
					width: 30%;
					border-radius: $radius;
					transition: all .3s linear;
					&:hover { background: rgba(0,0,0,0.04); }
				}
			}
			.old { color: $color-opacity; }
			.day {
				cursor: pointer;
				&.today { font-weight: bold; }
				&.active {
					font-weight: bold;
					color: #000;
				}
				&:hover { background: rgba(0,0,0,0.04); }
			}
		} 
		.datepicker-days table td {
			padding: ($spacing / 2) ($spacing / 3) !important;
			@media (max-width : 359px) { padding: $spacing / 4 !important; }
		}
		.focused { background: rgba(0,0,0,0.02) !important; }
	}

	@keyframes open_datepicker {
		from {
			transform: scale(0.96);
			opacity: 0;
		}
		to {
			transform: scale(1);
			opacity: 1;
		}
	}

//!* Idiomas [?]
	.form-file {
		.icon {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: white;
			img {
				max-height: 90%;
				max-width: 90%;
				width: auto;
				height: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				border-radius: 3px;
			}
		}
	}