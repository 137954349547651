//!* Vars
	$shadow-1: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.1);
	$shadow-2: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
	$shadow-3: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	$shadow-4: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.44);

	$background-body: #f0f0f0;
	$color-body: #616161;
	$color-border: #e0e0e0;
	$color-border-alt: #f0f0f0;
	$color-opacity: #9e9e9e;

	$spacing: 20px;
	$radius: 7px;
	$fsize: 15px;

	@mixin transform($transform) {
		-webkit-transform: $transform;
		-moz-transform: $transform;
		transform: $transform;
	}
	@mixin transition($transition) {
		-webkit-transition: $transition;
		-moz-transition: $transition;
		transition: $transition;
	}

//!* Colors
		$color-success: #1BAE9A;
		$color-success-alt: #ffffff;
		$color-error: #FC5F2D;
		$color-error-alt: #ffffff;
		$color-info: #4390DA;
		$color-info-alt: #ffffff;

		$redrum-1: #ffffff;
		$redrum-2: #ffffff;
		$redrum-3: #1d1934;