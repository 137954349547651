//!* Custom
	.content.form {
		max-width: 848px;
		margin: 0 auto;
	}

	.tab-pane {
		&:not(.active) {
			display: block;
			position: absolute;
			left: -99999vw;
			width: 0;
			height: 0;
		}
	}

	.sortable-images {
		> li {
			display: flex;
			align-items: center;

			> div {
				&:first-child {
					position: relative;
					height: 85px;
					border-radius: $radius;
					overflow: hidden;
					flex: 0 0 85px;
					align-self: flex-start;

					img {
						width: 100%;
					}

					.btns {
						position: absolute;
						bottom: 0.25rem;
						right: 0.25rem;
						z-index: 2;
						display: flex;
						gap: .25rem;
					}

					button {
						padding: 4px;

						&.is--success {
							background-color: $color-success !important;
							color: $redrum-1 !important;
							pointer-events: none;
						}
					}
				}

				&:last-child {
					padding-left: 1rem;
					flex-grow: 1;
				}
			}

			& + li {
				margin-top: 1rem;
			}
		}
	}

	.sortable-files {
		> li + li {
			margin-top: 1rem;
		}

		.link-default {
			min-width: 0;
			margin: .5rem 0;

			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				min-width: 0;
				display: block;
			}
		}

		.btn {
			padding: 5px;
			background: $redrum-3 !important;
			color: $redrum-2 !important;

			&.is--success {
				color: $redrum-1 !important;
				background-color: $color-success !important;
				pointer-events: none;
			}

			& + .btn {
				margin-left: .25rem;
			}
		}
	}

	.subtitle {
		font-weight: bold;
		text-transform: uppercase;
		border-left: 4px solid $redrum-3;
		padding: 4px 0 4px 15px;
		color: $redrum-3;
		font-size: 1.15em;
		margin: ($spacing * 3) 0 ($spacing * 2);
	}

	.medium-editor-hidden {
		position: absolute;
		z-index: -1;
		left: -9999em;
	}

	// .medium-editor-toolbar-form .medium-editor-toolbar-input, .medium-editor-toolbar-form label {
	// 	padding: 6px 12px;
	// }

	// .medium-editor-toolbar-form-active {
	// 	display: flex;
	// 	align-items: center;
	// 	flex-wrap: wrap;
	// }

	// .medium-editor-toolbar-form .medium-editor-toolbar-input, .medium-editor-toolbar-form label {
	// 	width: 220px;
	// }

	// .medium-editor-toolbar li button:hover {
	// 	color: #ffc628 !important;
	// }

	// .medium-editor-toolbar-save,
	// .medium-editor-toolbar-close {
	// 	display: block;
	// 	padding: 13px 6px;
	// 	margin: 0 !important;
	// 	font-size: 0 !important;
	// 	color: white !important;

	// 	&:hover {
	// 		color: #ffc628 !important;
	// 	}

	// 	&:before {
	// 		font-family: 'Material Icons';
	// 		font-weight: normal;
	// 		font-style: normal;
	// 		font-size: 24px;
	// 		line-height: 1;
	// 		letter-spacing: normal;
	// 		text-transform: none;
	// 		display: inline-block;
	// 		white-space: nowrap;
	// 		word-wrap: normal;
	// 		direction: ltr;
	// 		-webkit-font-feature-settings: 'liga';
	// 		-webkit-font-smoothing: antialiased;
	// 	}
	// }

	// .medium-editor-toolbar-save:before {
	// 	content: 'check';
	// }

	// .medium-editor-toolbar-close:before {
	// 	content: 'close';
	// }

	// .medium-editor-placeholder:after {
	// 	color: $color-opacity;
	// 	font-style: italic;
	// }

	.editable {
		h2, h3 {
			margin: 1.5em 0 1em;
			font-weight: bold;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h2 {
			font-size: 1.5em !important;
		}

		h3 {
			font-size: 1.25em !important;
		}

		p {
			margin: 0 0 1em !important;

			a {
				color: currentColor !important;
				opacity: .66;
				text-decoration: underline !important;

				&:hover {
					opacity: 1;
				}
			}

			&:last-child {
				margin-bottom: 0 !important;
			}
		}
	}

	// .form-editor {
	// 	margin: 0;

	// 	.label {
	// 		position: static !important;
	// 		margin-bottom: .5em;

	// 		a {
	// 			color: $color-body !important;
	// 			font-size: 90%;
	// 		}
	// 	}

	// 	> div {
	// 		background: white;
	// 		border-radius: $radius;
	// 		box-shadow: $shadow-1;

	// 		&:before {
	// 			content: '';
	// 			display: block;
	// 		}
	// 	}
	// }

	.editable-news {
		height: auto !important;
		min-height: 6rem;
		// padding: 4rem;
		// margin-top: 1.25rem;
		border-radius: $radius  !important;
		border: 1px solid $color-border  !important;
		border-bottom-width: 3px !important;
		background: $redrum-1  !important;
		font-size: 17px;
		font-family: "The Mix", "Verdana", "Times New Roman", Times, serif;
		font-weight: 400;
		color: $redrum-3;

		& ~ textarea {
			position: fixed;
			left: -100vw;
		}

		> * {
			font-family: "The Mix", "Verdana", "Times New Roman", Times, serif;
			display: block;
			margin: 0 !important;
			line-height: 1.31;
			color: $redrum-3;

			& + * {
				margin-top: 1.22rem !important;
			}
		}

		img {
			min-width: 75%;
			max-width: 100%;
			height: auto;
			display: block;
			margin-bottom: 1.5em;
	
			&:last-child { margin-bottom: 0; }
		}
	
		iframe {
			width: 100%;
			height: auto;
			aspect-ratio: 16 / 9;
			margin-bottom: 1.5em;
	
			&:last-child { margin-bottom: 0; }
		}
	
		b, strong {
			font-weight: 800;
		}

		p a:not(.btn),
		a:not(.btn) {
			opacity: 1;
			color: currentColor !important;
			transition: color 300ms ease !important;
			text-decoration: underline !important;
			text-decoration-color: $yellow !important;
			text-decoration-thickness: 2px !important;
			text-underline-offset: 3px;
		}

		.btn {
			display: inline-flex;
			align-items: center;
			line-height: 1em;
			white-space: nowrap;
			font-weight: 600 !important;
			letter-spacing: -0.01em;
			text-align: center;
			border: 2px solid #ffc629;
			color: $redrum-3 !important;
			user-select: none;
			padding: .81rem 1.82rem;
			border-radius: 0 !important;
			box-shadow: none !important;

			span {
				display: block;
				flex-grow: 1;
			}
		}

		ins {
			text-decoration: none !important;
		}

		h2, h3 {
			line-height: 1.1;
			margin-top: 1.9rem;
			margin-bottom: 1.3rem;
			font-weight: 800;
	
			* {
				font-weight: 800;
				font-size: 1em;
			}
	
			&:first-child { margin-top: 0; }
			&:last-child { margin-bottom: 0; }
		}
	
		blockquote,
		p,
		ul, 
		ol {
			margin-bottom: 1.3em;
			line-height: 1.6em;
	
			&:last-child { margin-bottom: 0; }
		}

		ul {
			li::marker {
				color: $yellow;
			}
		}
	
		blockquote {
			padding-left: 1.5rem;
			font-style: italic;
			border-left: 4px solid currentColor;
		}
	}

	.medium-toolbar-arrow-under:after {
		display: none;
	}

	.medium-editor-toolbar-form {
		position: relative;
		border-radius: $radius;
		background: $redrum-1;
		color: $color-body;

		.medium-editor-toolbar-input {
			background: $redrum-1;
			color: $color-body;
			width: 250px;
			margin-right: 70px;
			padding-left: 12px;
		}

		a {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 35px;
			transform: none;
			margin: 0 !important;
			color: currentColor !important;

			&.medium-editor-toolbar-save {
				right: 35px;
			}

			&:hover {
				color: $redrum-3 !important;
			}
		}

		.fa {
			position: relative;
			display: block;
			font-family: 'Material Icons';
			font-weight: normal;
			font-style: normal;
			font-size: 24px;
			line-height: 1;
			letter-spacing: normal;
			text-transform: none;
			display: inline-block;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			-webkit-font-feature-settings: 'liga';
			-webkit-font-smoothing: antialiased;
		}

		.fa-check:before { content: 'check'; }
		.fa-times:before { content: 'close'; }

		.medium-editor-toolbar-form-row {
			display: flex;
			align-items: center;
			margin: 0;
			padding: 12px;

			label {
				width: auto;
				padding: 0 0 0 .5em;
			}
		}
	}

	.medium-editor-toolbar {
		border-radius: $radius;
		background: $redrum-1;
		box-shadow: $shadow-1;
		border: none !important;

		li {
			button:hover,
			.medium-editor-button-active {
				background: $redrum-1;
				color: $redrum-3;

				&:before {
					opacity: .16;
				}
			}
		}
	}

	.medium-editor-toolbar-actions {
		padding: 5px 10px !important;

		li {
			& + li {
				margin-left: 1px;
			}

			button {
				position: relative;
				border-radius: 50px !important;
				line-height: 1;
				height: auto;
				min-width: 1px;
				padding: 6px 5px 3px !important;
				color: $color-body;
				background: none;
				border: none;
				z-index: 1;
				overflow: hidden;
				box-shadow: none;

				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					inset: 0;
					background: $redrum-3;
					opacity: 0;
				}
			}
		}

		.fa {
			position: relative;
			display: block;
			font-family: 'Material Icons';
			font-weight: normal;
			font-style: normal;
			font-size: 24px;
			line-height: 1;
			letter-spacing: normal;
			text-transform: none;
			display: inline-block;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			-webkit-font-feature-settings: 'liga';
			-webkit-font-smoothing: antialiased;

			sup {
				position: absolute;
				font-family: 'Roboto', system-ui, sans-serif;
				font-size: 11px;
				font-weight: bold;
				line-height: 1;
				top: auto;
				bottom: 4px;
				right: 0;
			}
		}

		.fa-bold:before { content: 'format_bold'; }
		.fa-italic:before { content: 'format_italic'; }
		.fa-underline:before { content: 'format_underlined'; }
		.fa-underline:before { content: 'format_underlined'; }
		.fa-strikethrough:before { content: 'strikethrough_s'; }
		.fa-header:before { content: 'title'; }
		.fa-quote-right:before { content: 'format_quote'; }
		.fa-link:before { content: 'link'; }
		.fa-list-ul:before { content: 'format_list_bulleted'; }
		.fa-list-ol:before { content: 'format_list_numbered'; }
		.fa-eraser:before { content: 'format_clear'; }
		.fa-picture-o:before { content: 'image'; }
		.fa-paint-brush:before { content: 'drive_file_rename_outline'; }
		.fa-code:before { content: 'code'; }
	}

	.medium-editor-anchor-preview {
		font-family: 'Roboto', system-ui, sans-serif;
		font-size: 14px;
		line-height: 1;

		&:before {
			display: none;
		}

		.medium-editor-toolbar-anchor-preview {
			border-radius: $radius;
			background: $redrum-1;
			color: $color-body;
			padding: 1rem;
			box-shadow: $shadow-1;

			a {
				margin: 0;
				color: currentColor !important;

				&:hover {
					color: $redrum-3 !important;
				}
			}
		}
	}

	.list-badges {
		gap: .5rem;

		strong {
			color: $redrum-3;
		}

		a {
			display: inline-block;
			font-size: 87%;
			padding: .15rem .5rem;
			color: $color-body !important;
			text-decoration: underline !important;

			&:hover {
				color: $redrum-3 !important;
			}
		}
	}

	.pagination {
		overflow: visible;
		gap: .5rem;
		padding: 0 0 1.75rem;

		.disabled {
			pointer-events: none;
			opacity: .25;
		}

		.btn {
			padding: .5rem;
			line-height: 1;
			display: block;
			font-weight: bold;
			color: $redrum-3 !important;

			&.btn-default-2 {
				color: $redrum-1 !important;
			}
		}
	}