//!* Resets default
	*, *:before, *:after {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		font-feature-settings: 'liga';
	}

	body {
		font-family: 'Roboto';
		background: $background-body;
		color: $color-body;
		font-size: $fsize;
		-webkit-font-smoothing: antialiased;
	}

	a {
		text-decoration: none !important;
		color: $color-body;
		@include transition(all .300ms ease);

		&.link {
			color: $color-body !important;
			text-decoration: underline !important;

			&:hover {
				opacity: 0.75;
			}
		}
	}

	p {
		margin: 0 0 $spacing * 2;
		line-height: 1.5em;

		&:last-child { margin: 0; }

		@media (max-width : 767px) { margin-bottom: $spacing; }
	}

	@media (min-width : 768px) {
		p + p { margin-top: -($spacing) !important; }
	}

	.lead { font-weight: 400; }

	hr {
		border-top: 1px solid $color-border;
		margin: ($spacing * 2) 0;

		@media (max-width : 767px) { margin: $spacing 0; }
	}

	form { margin: 0;}

	.mi-tooltip {
		font-size: 1em;
		cursor: help;
		opacity: .6;

		@include transition(opacity 300ms ease);

		&:hover {
			opacity: 1;
		}
	}

	.heading-block { margin-bottom: $spacing; }

	.font-light { font-weight: 300; }
	.font-regular { font-weight: 400; }
	.font-medium { font-weight: 500; }
	.font-bold { font-weight: 700; }
	.margin-top-lg { margin-top: $spacing; }
	.margin-bottom-lg { margin-bottom: $spacing; }
	.margin-top-lg-2x { margin-top: $spacing * 2; }
	.margin-bottom-lg-2x { margin-bottom: $spacing * 2; }
	.margin-top-lg-0 { margin-top: 0; }
	.margin-bottom-lg-0 { margin-bottom: 0; }
	@media (max-width : 1199px) {
		.text-center-md { text-align: center; }
		.text-left-md { text-align: left; }
		.text-right-md { text-align: right; }
		.margin-top-md { margin-top: $spacing; }
		.margin-bottom-md { margin-bottom: $spacing; }
		.margin-top-md-2x { margin-top: $spacing * 2; }
		.margin-bottom-md-2x { margin-bottom: $spacing * 2; }
		.margin-top-0 { margin-top: 0; }
		.margin-bottom-0 { margin-bottom: 0; }
	}
	@media (max-width : 991px) {
		.text-center-sm { text-align: center; }
		.text-left-sm { text-align: left; }
		.text-right-sm { text-align: right; }
		.margin-top-sm { margin-top: $spacing; }
		.margin-bottom-sm { margin-bottom: $spacing; }
		.margin-top-sm-2x { margin-top: $spacing * 2; }
		.margin-bottom-sm-2x { margin-bottom: $spacing * 2; }
		.margin-top-sm-0 { margin-top: 0; }
		.margin-bottom-sm-0 { margin-bottom: 0; }
	}	
	@media (max-width : 767px) {
		.text-center-xs { text-align: center; }
		.text-left-xs { text-align: left; }
		.text-right-xs { text-align: right; }
		.margin-top-xs { margin-top: $spacing; }
		.margin-bottom-xs { margin-bottom: $spacing; }
		.margin-top-xs-2x { margin-top: $spacing * 2; }
		.margin-bottom-xs-2x { margin-bottom: $spacing * 2; }
		.margin-top-xs-0 { margin-top: 0; }
		.margin-bottom-xs-0 { margin-bottom: 0; }
	}

	h1 {
		margin: ($spacing * 2) 0;
		font-size: 42px;
		font-weight: 400;

		small {
			font-size: 24px;
		}

		@media (max-width : 767px) {
			margin: $spacing 0;
			font-size: 30px;

			small {
				font-size: 18px;
			}
		}
	}

	h2 {
		font-size: 30px;
		font-weight: 400;
		margin: ($spacing * 2) 0;

		@media (max-width : 767px) {
			font-size: 20px;
			margin: $spacing 0;

			small {
				font-size: 17px;
			}
		}
	}

	h3 {
		font-size: 24px;
		font-weight: 400;
		margin: $spacing 0;

		@media (max-width : 767px) {
			font-size: 15px;

			small {
				font-size: 13px;
			}
		}
	}

	h1, h2, h3 {
		color: #323232;

		.material-icons { font-size: .95em !important; }

		&:first-child { margin-top: 0; }

		.btn .material-icons {
			font-size:  24px !important;
		}

		.block {
			display: block;
			padding: ($spacing / 2) 0;
		}

		small {
			font-size: 66%;
			color: $color-opacity;
		}
	}

	.prp {
		position: relative;
		overflow: hidden;
		border-radius: $radius;
		display: block;
		box-shadow: $shadow-1;
		background: white;

		&.square { padding-bottom: 100%; }

		> * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

//!* Resets
	.inline { display:inline-block; }

	.material-icons { vertical-align: middle; }

	.badge {
		font-size: 13px;
		line-height:13px;
		vertical-align: middle;
		background: none;
		background: $color-border;
		color: $color-body;
		font-weight: 500;
		border-radius: $radius;
		padding: 4px 12px !important;
		&.circle { border-radius: 50px; }
		&.new {
			background: $redrum-3;
			color: white !important;
			border: none !important;
		}
		&.timer {
			background: #ffc629;
			color: $redrum-3 !important;
			border: none !important;

		}
		&.white {
			background: $redrum-1;
			box-shadow: $shadow-1;
		}
	}

	.panel {
		box-shadow: none;
		border: none;
		background: none;
		margin: 0 !important;
		border-radius: 0 !important;
	}

	.padding-box { padding: $spacing; }
	.white { background: white; }
	.gray { background: $background-body; }
	.shadow { box-shadow: $shadow-1; }
	.radius { border-radius: $radius; }
	.text-danger, .link-default.text-danger { color: $color-error !important; }
	.text-success, .link-default.text-success { color: $color-success !important; }

	.tabs-group::-webkit-scrollbar, nav::-webkit-scrollbar { width: 0px; height: 0; }

	.panel-collapse {
		> div {
			padding: 0 $spacing $spacing; 

			> div {
				border-radius: $radius;
				background: $background-body;
			}
		}
	}